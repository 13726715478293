import React from 'react';
import { Typography } from '@mui/material';
import {
	UserForContractSentEventFeed,
	getCustomerLinksAsVendorOrAPPAForContractEvent,
	getCustomerNamesAsNonLinkComponent,
	DefaultEventProps,
} from './helpers';
// Lib
import { getDetailLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';

type ContractExecutedEvent = DefaultEventProps & {
	event: { contacts: { customer: { id: string } }[] };
};

const getContractExecutedEventProps = (
	eventProps: ContractExecutedEvent,
	user: UserForContractSentEventFeed
) => {
	if (
		!eventProps.event.contract ||
		typeof eventProps.event.contract === 'undefined'
	) {
		throw new Error( 'No contract provided' );
	}
	const { event } = eventProps;
	eventProps.icon = 'contract-signed';
	eventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: getDetailLink( {
				object: eventProps.event.contract,
				type: LinkObjectType.Contract,
			} ),
		},
	];
	const userIsCustomerOnContract = eventProps.event.contacts.some(
		( contact ) =>
			'id' in contact.customer && contact.customer.id === user.user?.id
	);
	if ( !userIsCustomerOnContract ) {
		// you are somehow associated with the vendor org
		const userIsNotInContractOrg =
			eventProps.event.vendor.id !== user.organization?.id;

		eventProps.message = (
			<Typography>
				The contract { linkObject( eventProps.event.contract ) }
				{ userIsNotInContractOrg ? ` from ${ event.vendor.name }` : '' } for { '' }
				{ userIsNotInContractOrg
					? getCustomerNamesAsNonLinkComponent( event )
					: getCustomerLinksAsVendorOrAPPAForContractEvent(
						event,
						user,
						false
					  ) }{ ' ' }
				{ '' }
				is now complete.
			</Typography>
		);
	} else {
		// you are a customer
		console.log( 'userIsCustomerOnContract', userIsCustomerOnContract );
		eventProps.message = (
			<Typography>
				Your contract { linkObject( eventProps.event.contract ) } is now complete.
			</Typography>
		);
	}
	return eventProps;
};

export default getContractExecutedEventProps;

/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Minus = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M18.5 11.25c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-13c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h13z'
			transform='translate(-183 -804) translate(111 144) translate(72 56) translate(0 562) translate(0 42)'
		/>
	</svg>
);

export default Minus;

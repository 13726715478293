import {
	getOrganizationPlan,
	isClient,
	isOrgUser,
	recurseAnyTruncateStrings,
	unsetCookie,
} from './index';
import { getSegmentRouteFromUrl } from './getSegmentRouteFromUrl';

/**
 * Analytics.js, Segment's javascript source, lets you track the following basic methods:
 * Identify, Track, Page and Group.
 *
 * Each method requires a set of properties (or traits) to be sent as a payload.
 * With the usage of Window.analytics.{method}, data gets tracked in Segment.
 */

/**
 * Tracked Event Types
 * */
const CLICKED_DOWNLOAD = { event: 'Clicked Download', feature: 'Resources' };
const CLICKED_LINK = {
	event: 'Clicked Link',
	feature: 'Resources',
	location: '/resources',
};
const COMPLETED_ONBOARDING = {
	event: 'Completed Onboarding',
	feature: 'Onboarding',
};
const SEARCHED_RESOURCES = {
	event: 'Searched Resources',
	feature: 'Resources',
	location: '/resources',
};
const SENT_INVOICE = { event: 'Sent Invoice', feature: 'Invoices' };
const SUBMITTED_STRIPE_NEEDS = {
	event: 'Submitted Stripe Needs',
	feature: 'Wallet',
	location: '/wallet',
};
const UPDATED_SUBSCRIPTION = {
	event: 'Updated Subscription',
	feature: 'Subscriptions',
	location: '/admin',
};

/**
 * Waits for intercom object to load on window object.
 * Times-out after 30 seconds
 * @return { Promise }
 */
const getIntercom = () => {
	if ( window?.Intercom ) {
		return Promise.resolve( window.Intercom );
	} else {
		return new Promise( ( resolve ) => {
			let timeout = 0;
			const interval = setInterval( () => {
				timeout++;
				if ( window?.Intercom ) {
					// make sure to kill the interval once we're done with it
					clearInterval( interval );
					resolve( window.Intercom );
				} else if ( timeout > 30 ) {
					// make sure to kill the interval once we're done with it
					clearInterval( interval );
					resolve();
				}
			}, 500 );
		} );
	}
};

/**
 * Identifies Groups in Segment
 *
 * @param {Object} user
 *
 */
const segmentGroup = async ( user ) => {
	if ( !user || !window?.analytics ) return;
	if ( isOrgUser( user ) && user.organization ) {
		const org = user.organization;
		const traits = {
			userId: user?.user?.id,
			name: org.name,
			planStatus: org.subscription?.status,
			planPeriod: org.subscription?.subscriptionPlan?.period,
			planType: org.subscription?.subscriptionPlan?.type,
			orgServices: org.services.map( ( service ) => service.name ).join( ',' ),
			street: org.addressLine1,
			street2: org.addressLine2,
			city: org.city,
			region: org.state,
			postalCode: org.postalCode,
			country: org.country,
			description: org.description,
			website: org.website,
			twitter: org.twitter,
			facebook: org.facebook,
			instagram: org.instagram,
			pinterest: org.pinterest,
			helloSignId: org.helloSignID,
			stripeId: org.stripeID,
			hasStripeVerificationNeeds: org.hasStripeVerificationNeeds,
			stripeSubscriptionCustomerID: org.stripeSubscriptionCustomerID,
			canReceivePayments: org.canReceivePayments,
		};

		if ( org.coupon ) {
			traits.promoCode = org.coupon.name;
		}

		window.analytics.group( org.id, recurseAnyTruncateStrings( traits ) );
	}
};

/**
 * Builds properties for 'COMPLETED_ONBOARDING' event
 * Builds properties for SENT_INVOICE event
 *
 * @param {Object} event
 * @param {String} event.feature
 * @param {Object} payload
 * @param {Object} payload.user - base user
 * @param {String} payload.user.id - base user ID
 *
 * @returns {Object} properties
 */
const getPropertiesForCompletedOnboarding = ( event, payload ) => {
	if ( !event || !payload ) return;
	return {
		feature: event.feature,
	};
};

/**
 * @param {Object} payload.invoice - the invoice that was sent
 * @param {String} payload.invoice.id - the id of the invoice
 * @param {String} payload.invoice.title - The title of the invoice
 * @param {Number} payload.invoice.totalAmount - the invoice total represented as pennies
 *
 * @returns {Object} properties
 */
const getPropertiesForSentInvoice = ( event, payload ) => {
	if ( !event || !payload ) return;
	const splitTotal = payload.invoice.totalAmount.toString().split( '' );
	const length = splitTotal.length;
	splitTotal.splice( length - 2, 0, '.' );
	const stringTotal = splitTotal.join( '' );
	/** @type { string | Date | null | undefined } */
	const rawDueDate = payload.invoice.paymentInstallments[ 0 ].dueDate;
	const nextDueDate = rawDueDate
		? typeof rawDueDate === 'string'
			? rawDueDate
			: rawDueDate.toISOString()
		: undefined;
	const properties = {
		feature: event.feature,
		invoiceId: payload.invoice.id,
		invoiceTitle: payload.invoice.title,
		invoiceTotal: stringTotal,
		nextDueDate,
		page: payload.page,
	};
	return properties;
};

/**
 * Sets up properties for Searched items tracking
 *
 * @param {Object} event
 * @param {Object} payload
 *
 * @returns { Promise<object> } properties
 */
const getPropertiesOfSearchedItems = async ( event, payload ) => {
	if ( !event || !payload ) return;
	return {
		feature: event.feature,
		page: event.location,
		value: payload.query,
		plan: await getOrganizationPlan( payload.user.organization ),
	};
};

/**
 * Sets up properties for Clicked Download resource tracking
 *
 * @param {Object} event
 * @param {Object} payload
 *
 * @returns { Promise<object> } properties
 */
const getPropertiesOfClickedDownloadResource = async ( event, payload ) => {
	if ( !event || !payload ) return;
	return {
		feature: event.feature,
		resourceId: payload.resource.id,
		resourceName: payload.resource.description,
		resourceCategory: payload.resource.category,
		resourceAvailableUntil: payload.resource.availableUntil,
		sponsorName: payload.resource.sponsor.name,
		sponsorId: payload.resource.sponsor.id,
		plan: await getOrganizationPlan( payload.user?.organization ),
	};
};

/**
 * Sets up properties for Clicked Sponsor tracking
 *
 * @param {Object} event
 * @param {Object} payload
 *
 */
const getPropertiesOfClickedSponsor = async ( event, payload ) => {
	if ( !event || !payload ) return;
	return {
		feature: event.feature,
		page: payload.link,
		linkUrl: payload.sponsor?.sponsorLink,
		plan: await getOrganizationPlan( payload.user?.organization ),
	};
};

/**
 * Sends Tracked events over to Segment
 *
 * @param {Object} event
 * @param {Object} payload
 *
 */
const segmentEvent = async ( event, payload ) => {
	if ( !event || !payload || !window?.analytics ) return;
	let properties = {};

	if ( payload?.query === '' ) {
		return;
	}

	switch ( event ) {
		case CLICKED_DOWNLOAD:
			properties = await getPropertiesOfClickedDownloadResource( event, payload );
			break;
		case SEARCHED_RESOURCES:
			properties = await getPropertiesOfSearchedItems( event, payload );
			break;
		case CLICKED_LINK:
			properties = await getPropertiesOfClickedSponsor( event, payload );
			break;
		case COMPLETED_ONBOARDING:
			properties = getPropertiesForCompletedOnboarding( event, payload );
			break;
		case SENT_INVOICE:
			properties = getPropertiesForSentInvoice( event, payload );
			break;
		case SUBMITTED_STRIPE_NEEDS:
			properties = { feature: event.feature };
			break;
	}
	window.analytics.track( event.event, recurseAnyTruncateStrings( properties ) );
};

/**
 * Sets up traits for Org users identification
 *
 * @param {Object} user
 *
 * @returns {Object} trait
 */
const setOrgUserIdentifyTraits = ( user ) => {
	if ( !user ) return;
	return {
		firstName: user?.firstName,
		lastName: user?.lastName,
		email: user?.user?.email,
		userType: user?.user?.userType,
		phone: user?.phone,
		role: user?.isOwner ? 'Owner' : user.isAdmin ? 'Admin' : 'Non-admin',
	};
};

/**
 * Sets up traits for Client users identification
 *
 * @param {Object} user
 *
 * @returns {Object} trait
 */
const setClientUserIdentifyTraits = ( user ) => {
	if ( !user || !window?.analytics ) return;
	return {
		firstName: user.firstNameOne,
		lastName: user.lastNameOne,
		email: user.user?.email,
		userType: user.user?.userType,
		phone: user.phone,
		street: user.addressLine1,
		street2: user.addressLine2,
		city: user.city,
		region: user.state,
		postalCode: user.postalCode,
		country: user.country,
		twitter: user.twitter,
		facebook: user.facebook,
		instagram: user.instagram,
		pinterest: user.pinterest,
		stripeId: user.user?.stripeCustomerId,
	};
};

/**
 * Sets up traits for Guest users identification
 *
 * @param {Object} user
 *
 * @returns {Object} trait
 */
const setGuestUserIdentifyTraits = ( user ) => {
	if ( !user ) return;
	return {
		email: user.email,
		stripeId: user.user?.stripeCustomerId,
		userType: user.userType,
	};
};

/**
 * Identifies Users in Segment
 *
 * @param {Object} user
 *
 */
const segmentIdentify = ( user ) => {
	let traits = {};
	if ( !user || !window?.analytics ) {
		return;
	}

	if ( isOrgUser( user ) ) {
		traits = setOrgUserIdentifyTraits( user );
	} else if ( isClient( user ) ) {
		traits = setClientUserIdentifyTraits( user );
	} else if ( user.userType === 'GuestUser' ) {
		traits = setGuestUserIdentifyTraits( user );
	}
	window.analytics.identify( user.user.id, recurseAnyTruncateStrings( traits ) );
};

/**
 * Clears the user Traits and userId from LocalStorage
 */
export const cleanSegmentTraitAndId = () => {
	if ( typeof window !== 'undefined' ) {
		window.localStorage.removeItem( 'ajs_user_traits' );
		window.localStorage.removeItem( 'ajs_user_id' );
		window.localStorage.removeItem( 'ajs_anonymous_id' );
	}
	unsetCookie( '__stripe_sid' );
	unsetCookie( '__stripe_mid' );
};

/**
 * Sends Page views events over to Segment
 *
 * @param {Object} [user]
 * @param {String} url
 *
 */
const segmentPage = async ( user, url ) => {
	if ( !url || !window?.analytics ) return;
	const route = getSegmentRouteFromUrl( url );
	const properties = {
		userId: user?.user?.id,
		referrer: undefined,
		path: undefined,
		search: undefined,
		title: undefined,
	};

	if ( route ) {
		if ( route.hasOwnProperty( 'getObjectName' ) ) {
			properties.objectName = await route.getObjectName(
				route.objectId,
				user?.token
			);
		}
		window.analytics.page( undefined, route.name, properties );
	}
};

export {
	getIntercom,
	segmentEvent,
	segmentGroup,
	segmentIdentify,
	segmentPage,
	SENT_INVOICE,
	CLICKED_LINK,
	SEARCHED_RESOURCES,
	CLICKED_DOWNLOAD,
	UPDATED_SUBSCRIPTION,
	COMPLETED_ONBOARDING,
	SUBMITTED_STRIPE_NEEDS,
};

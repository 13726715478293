import React from 'react';
import { Typography } from '@mui/material';
import {
	UserForContractSentEventFeed,
	userIsActingUserOnEvent,
	getCustomerLinksAsVendorOrAPPAForContractEvent,
	getCustomerNamesAsNonLinkComponent,
	DefaultEventProps,
} from './helpers';
// Lib
import { getDetailLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';

const getContractSignedByCustomerEventProps = (
	eventProps: DefaultEventProps,
	user: UserForContractSentEventFeed
) => {
	if (
		!eventProps.event.contract ||
		typeof eventProps.event.contract === 'undefined'
	) {
		throw new Error( 'No contract provided' );
	}
	const { event } = eventProps;
	eventProps.icon = 'contract-signed';
	eventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: getDetailLink( {
				object: eventProps.event.contract,
				type: LinkObjectType.Contract,
			} ),
		},
	];
	if ( !userIsActingUserOnEvent( event, user ) ) {
		// you are somehow associated with the vendor org
		const userIsNotInContractOrg =
			eventProps.event.vendor.id !== user.organization?.id;

		eventProps.message = (
			<Typography>
				{ linkObject( eventProps.event.contract ) }
				{ userIsNotInContractOrg ? ` from ${ event.vendor.name }` : '' } has
				received a signature for{ ' ' }
				{ userIsNotInContractOrg
					? getCustomerNamesAsNonLinkComponent( event )
					: getCustomerLinksAsVendorOrAPPAForContractEvent( event, user, false ) }
				.
			</Typography>
		);
	} else if ( userIsActingUserOnEvent( event, user ) ) {
		// you are a customer
		eventProps.message = (
			<Typography>
				You signed { linkObject( eventProps.event.contract ) }.
			</Typography>
		);
	} else {
		//eslint-disable-next-line max-len
		throw new Error(
			`getContractResentEventProps unable to determine user role.\n event:\n ${ JSON.stringify(
				event,
				null,
				2
			) }\n user: \n ${ user } `
		);
	}
	return eventProps;
};

export default getContractSignedByCustomerEventProps;

import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { getEnumKeyByValue } from '../../lib/helpers/apollo';
import MuiStepper from '../../mui/MuiStepper';
import styles from './OrgOnboarding.module.scss';
import YourInfo from './YourInfo';
import useYourInfo from './YourInfo/useYourInfo';
import YourBusiness from './YourBusiness';
import FinishUp from './FinishUp';
import {
	GuestUser,
	SuperAdmin,
	ClientUser,
	OrgUser,
	UserRegistration,
} from '../../types/user';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoadingOverlay } from '../../redux/actions';
import useYourBusiness from './YourBusiness/useYourBusiness';
import useFinishUp from './FinishUp/useFinishUp';
import { capitalize, PageHead, PageHeadBaseProps } from '../helpers';

export enum OrgOnboardingSteps {
	YourInfo = 'your-info',
	YourBusiness = 'your-business',
	FinishUp = 'finish-up',
}

export type OrgOnboardingStepKeys = `${ OrgOnboardingSteps }`;

const getNextStep = ( step: OrgOnboardingStepKeys ) => {
	switch ( step ) {
		case OrgOnboardingSteps.YourInfo:
			return OrgOnboardingSteps.YourBusiness;
		case OrgOnboardingSteps.YourBusiness:
			return OrgOnboardingSteps.FinishUp;
	}
};

const getPreviousStep = ( step: OrgOnboardingStepKeys ) => {
	switch ( step ) {
		case OrgOnboardingSteps.YourBusiness:
			return OrgOnboardingSteps.YourInfo;
		case OrgOnboardingSteps.FinishUp:
			return OrgOnboardingSteps.YourBusiness;
	}
};

enum Directions {
	Next = 'next',
	Back = 'back',
}

const getPath = ( direction: Directions, currentStep: OrgOnboardingStepKeys ) =>
	direction === Directions.Back
		? `/orgOnboarding/${ getPreviousStep( currentStep ) }`
		: currentStep === 'finish-up'
			? '/'
			: `/orgOnboarding/${ getNextStep( currentStep ) }`;

const stepLabels = {
	[ OrgOnboardingSteps.YourInfo ]: 'Your info',
	[ OrgOnboardingSteps.YourBusiness ]: 'Your business',
	[ OrgOnboardingSteps.FinishUp ]: 'Finish up',
};

export type OrgOnboardingProps = PageHeadBaseProps & {
	step: OrgOnboardingStepKeys;
};

type OrgUserForOnboarding = OrgUser & {
	organization?: OrgUser['organization'];
};

type FlattenedUserForOnboarding =
	| OrgUserForOnboarding
	| ClientUser
	| SuperAdmin
	| GuestUser
	| undefined;

const OrgOnboarding: React.FC<OrgOnboardingProps> = ( {
	step: stepValue,
	hostnameAndProtocol,
} ) => {
	const user: FlattenedUserForOnboarding = useSelector(
		( state: any ) => state?.user
	);
	const userRegistration: UserRegistration = useSelector(
		( state: any ) => state?.userRegistration
	);
	const router = useRouter();

	// if we get here from sign-in then we have to turn off the loading indicator
	const dispatch = useDispatch();
	useEffect( () => {
		dispatch( toggleLoadingOverlay( false ) );
	}, [ dispatch ] );

	// If we have neither a user nor a userRegistration, then bail out and force the user to use their link again
	if ( !user?.isLoggedIn && !userRegistration?.coreUserFields ) {
		router.push( '/Login' );
	}

	// So we can make the buttons spinners when one is clicked
	const [ loading, setLoading ] = useState( false );
	useEffect( () => {
		setLoading( false );
	}, [ stepValue ] );

	// translate the enum string value into an enum value
	const step = OrgOnboardingSteps[
		getEnumKeyByValue(
			OrgOnboardingSteps,
			stepValue
		) as keyof typeof OrgOnboardingSteps
	] as OrgOnboardingSteps;

	// cook down some values we'll use with the stepper
	const activeStep = Object.values(
		OrgOnboardingSteps as Record<string, string>
	).indexOf( step );

	const {
		firstName,
		setFirstName,
		firstNameValidation,
		lastName,
		setLastName,
		lastNameValidation,
		onNext: onYourInfoNext,
		onBack: onYourInfoBack,
		onValidate: onYourInfoValidate,
	} = useYourInfo();

	const {
		businessName,
		setBusinessName,
		businessNameValidation,
		businessType,
		setBusinessType,
		businessTypeValidation,
		selectedServices,
		setSelectedServices,
		selectedServicesValidation,
		address,
		setAddress,
		addressValidation,
		phone,
		setPhone,
		phoneValidation,
		image,
		setImage,
		termsOfServiceAccepted,
		setTermsOfServiceAccepted,
		termsOfServiceAcceptedValidation,
		onNext: onYourBusinessNext,
		onBack: onYourBusinessBack,
		onValidate: onYourBusinessValidate,
	} = useYourBusiness();

	const {
		categoryExposure,
		setCategoryExposure,
		categoryExposureValidation,
		otherExposure,
		setOtherExposure,
		promoCode,
		setPromoCode,
		verifyPromoCode,
		promoCodeValidation,
		promoCodeVerificationLoading,
		promoCodeVerbiage,
		onNext: onFinishUpNext,
		onValidate: onFinishUpValidate,
	} = useFinishUp();

	// begin all of the actual work of onboarding the user
	const onNext = useCallback( async () => {
		if ( step === OrgOnboardingSteps.YourInfo ) {
			return await onYourInfoNext();
		} else if ( step === OrgOnboardingSteps.YourBusiness ) {
			return await onYourBusinessNext();
		} else if ( step === OrgOnboardingSteps.FinishUp ) {
			return await onFinishUpNext();
		}
		return true;
	}, [
		onYourInfoNext,
		onYourBusinessNext,
		onFinishUpNext,
		step
	] );

	const handleOnNext = useCallback( async () => {
		setLoading( true );
		const passesValidation = await onNext();
		if ( passesValidation ) {
			await router.push( getPath( Directions.Next, stepValue ) );
		} else {
			setLoading( false );
		}
	}, [
		onNext,
		router,
		stepValue
	] );

	// handle clicking the back button - remember that we use the onBack handler of the step we're _going in to__
	const onBack = useCallback( async () => {
		if ( step === OrgOnboardingSteps.YourBusiness ) {
			return onYourInfoBack();
		} else if ( step === OrgOnboardingSteps.FinishUp ) {
			return onYourBusinessBack();
		}
	}, [
		onYourBusinessBack,
		onYourInfoBack,
		step
	] );

	const handleOnBack = useCallback( async () => {
		setLoading( true );
		await onBack();
		await router.push( getPath( Directions.Back, stepValue ) );
	}, [
		onBack,
		router,
		stepValue
	] );

	return (
		<>
			<PageHead
				hostnameAndProtocol={ hostnameAndProtocol }
				title={ `Organization Onboarding > ${ capitalize( step.split( '-' ) ) }` }
				path={ `/orgOnboarding/${ step }` }
			/>
			<main className={ styles.container }>
				<MuiStepper
					steps={ OrgOnboardingSteps }
					// more enum casting nonsense
					labels={ stepLabels }
					activeStep={ activeStep }
					onNext={ handleOnNext }
					onBack={ handleOnBack }
					loading={ loading || promoCodeVerificationLoading }
				>
					{ step === OrgOnboardingSteps.YourInfo ? (
						<YourInfo
							firstName={ firstName }
							setFirstName={ setFirstName }
							firstNameValidation={ firstNameValidation }
							lastName={ lastName }
							setLastName={ setLastName }
							lastNameValidation={ lastNameValidation }
							onBlur={ () => onYourInfoValidate() }
						/>
					) : null }
					{ step === OrgOnboardingSteps.YourBusiness ? (
						<YourBusiness
							businessName={ businessName }
							setBusinessName={ setBusinessName }
							businessNameValidation={ businessNameValidation }
							businessType={ businessType }
							setBusinessType={ setBusinessType }
							businessTypeValidation={ businessTypeValidation }
							selectedServices={ selectedServices }
							setSelectedServices={ setSelectedServices }
							selectedServicesValidation={ selectedServicesValidation }
							address={ address }
							setAddress={ setAddress }
							addressValidation={ addressValidation }
							phone={ phone }
							setPhone={ setPhone }
							phoneValidation={ phoneValidation }
							image={ image }
							setImage={ setImage }
							termsOfServiceAccepted={ termsOfServiceAccepted }
							setTermsOfServiceAccepted={ setTermsOfServiceAccepted }
							termsOfServiceAcceptedValidation={
								termsOfServiceAcceptedValidation
							}
							onBlur={ () => onYourBusinessValidate() }
						/>
					) : null }
					{ step === OrgOnboardingSteps.FinishUp ? (
						<FinishUp
							categoryExposure={ categoryExposure }
							setCategoryExposure={ setCategoryExposure }
							categoryExposureValidation={ categoryExposureValidation }
							otherExposure={ otherExposure }
							setOtherExposure={ setOtherExposure }
							promoCode={ promoCode }
							setPromoCode={ setPromoCode }
							verifyPromoCode={ verifyPromoCode }
							promoCodeVerificationLoading={ promoCodeVerificationLoading }
							promoCodeVerbiage={ promoCodeVerbiage }
							onBlur={ () => onFinishUpValidate() }
							promoCodeValidation={ promoCodeValidation }
						/>
					) : null }
				</MuiStepper>
			</main>
		</>
	);
};

export default OrgOnboarding;

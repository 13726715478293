import App from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { Provider } from 'react-redux';
import store from '../redux/store';
import { updateUser, logout, login } from '../redux/actions';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import Router from 'next/router';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/nextjs';
import ApolloContextProvider from '../context/ApolloContextProvider';
import MuiThemeProvider from '../context/MuiThemeProvider';
import { FullScreenSpinner } from '../blocks/FullScreenSpinner';
import MuiXLicense from '../mui/MuiXLicense';

const relativeTime = require( 'dayjs/plugin/relativeTime' );
dayjs.extend( relativeTime );

// Styles
import styles from '../styles/style.scss'; // eslint-disable-line
import '../styles/nprogress.css';
import '../styles/ie.css';

// Mark up
import Page from '../blocks/Page';

// Utils
import API from '../lib/API';
import {
	flattenUser,
	getCookieString,
	getInfoFromCookie,
	intercomData,
	Redirect,
	unsetCookie,
} from '../lib/helpers';
import Globals from '../lib/Globals';
import errorReporting from '../lib/ErrorReporting';
import {
	getIntercom,
	segmentGroup,
	segmentIdentify,
	segmentPage,
} from '../lib/helpers/segment';
import favicon from '../../public/favicon.ico';
import analytics from '../../public/analytics.js';
import { OrgOnboardingSteps } from '../blocks/OrgOnboarding';
import { showError } from '../blocks/Toast';

// Extrapolate this gating logic so we can use it in the constructor and in the handler for routing
const trackPageView = async ( url, userOrToken ) => {
	/* A few situations do their own tracking, so "poke holes" here so that we don't try to track in both places
	You might think from below that Guest Contracts might need the same treatment as guest invoices. That does
	not appear to be the case. We seem to have written contracts, and the "new" invoice view, such that the
	segment tracking is handled here and not duplicated in page code. */
	const canUseAppFileSegmentTracking = ( url ) =>
		/^\/(quickPayment|leads)\//.test( url ) === false;
	if (
		url &&
		canUseAppFileSegmentTracking( url ) &&
		typeof window !== 'undefined'
	) {
		await segmentPage( userOrToken, url );
	}
};

class _app extends App {
	constructor( props ) {
		super( props );
		// set client-side redux state
		if ( typeof window === 'object' && props.user ) {
			store.dispatch( login( props.user ) );
		}
		API.getFeatureFlags().then( ( response ) => {
			if ( response.errors ) {
				response.errors.forEach( ( error ) => showError( error ) );
			} else {
				// Because segment code tries to fetch it's own data, we need to give it the token from the URL for guest requests
				const token =
					props.pageProps?.token || // for proposals
					props.pageProps?.props?.invoiceToken || // for invoices (new view)
					props.pageProps?.props?.guestToken; // for contracts
				trackPageView( props.asPath, props.user || { token } );
			}
		} );

		this.state = {
			isMounted: false,
		};
	}

	/**
	 *
	 * @param {{ Component: any, ctx: import('next').NextPageContext }} params
	 * @returns
	 */
	static async getInitialProps( { Component, ctx } ) {
		try {
			let token = null;
			let userCookie = null;
			const props = {};
			props.track = false;
			const { req = null, res = null, pathname = '', asPath = '' } = ctx;
			props.asPath = asPath;
			const cookieString = getCookieString( req );
			if ( cookieString ) {
				[ token, userCookie ] = getInfoFromCookie( cookieString );
				if ( token ) {
					props.token = token;
					API.setAuthToken( token );
					props.track = true;
				}
			}

			if ( userCookie && token ) {
				const { user, groups } = await API.getAuthedUser( userCookie.email );
				if ( user ) {
					// Do not update user state if server-side
					props.user = flattenUser( user, groups );
					if ( typeof window !== 'undefined' ) {
						store.dispatch( updateUser( props.user, ctx ) );
					}
				} else {
					store.dispatch( logout( true, ctx ) );

					return {};
				}
			}

			props.featureFlags = [];
			const { featureFlags } = await API.getFeatureFlags();
			if ( featureFlags ) props.featureFlags = featureFlags;

			if (
				/\/(_error|timelines|SignUp|Login|ResetPassword|quickPayment\/\S+|leads?\/\S+|document\/\S+)/.test(
					pathname
				)
			) {
				// DON'T REDIRECT - in the case of contact or client redirects we use next.js to do the redirect there
			} else {
				if ( !token || !props.user ) {
					// redirect users who are not logged in and not actively registering to the login/landing page
					if ( !Globals.anonymousRoutes.includes( pathname ) ) {
						unsetCookie( 'CurrentUser', ctx );
						unsetCookie( 'Authorization', ctx );
						if ( asPath === '/' ) {
							// no need to include home page redirect here
							Redirect( res, '/Login' );
						} else {
							Redirect( res, {
								pathname: '/Login',
								query: { returnTo: asPath },
							} );
						}

						return {};
					}
					if (
						// onboarding routes do not generally expect tokens
						!Globals.onBoardingRoutes.includes( pathname ) &&
						// but all other anonymous routes do
						Globals.anonymousRoutes.includes( pathname ) &&
						// and the names of those tokens are
						!asPath.includes( 'token' ) &&
						!asPath.includes( 'oauth_state_id' )
						// so, if we're on an anonymous route, we expect it to EITHER be an onboarding route
						// or another anonymous route with a token param
						// if this is not the case, we should redirect to login
					) {
						Redirect( res, { pathname: '/Login', query: { returnTo: asPath } } );
					}
				} else if ( props.user && !props.user?.user?.onboardingCompleted ) {
					// otherwise, if they're a user but haven't completed onboarding
					const { user: upToDateUser } = await API.getAuthedUser(
						props.user?.user?.email
					);
					const redirectParams = {};
					// we should send them to the appropriate onboarding route to finish up
					if ( props.user?.userType === 'ClientUser' ) {
						redirectParams.pathname = '/ClientSignUp/[page]';
						redirectParams.as = '/ClientSignUp/1';
					} else if ( props.user?.isAdmin || !props.user?.organization ) {
						// END: we can remove this chunk after we finish the new org onboarding work
						redirectParams.pathname = '/orgOnboarding/[step]';
						redirectParams.as = `/orgOnboarding/${ OrgOnboardingSteps.YourInfo }`;
					} else {
						redirectParams.pathname = '/OrgMemberSignUp/[page]';
						redirectParams.as = '/OrgMemberSignUp/1';
					}
					if (
						( !upToDateUser?.user?.onboardingCompleted &&
							!(
								redirectParams.pathname.includes( pathname ) ||
								pathname.includes( redirectParams.pathname )
							) ) ||
						pathname === '/'
					) {
						Redirect( res, redirectParams );
						return props;
					}
				} else if (
					Globals.superAdminRoutes.includes( pathname ) &&
					!props.user.isSuperAdmin
				) {
					Redirect( null, '/' );
					return {};
				}
			}

			props.pageProps = Component.getInitialProps
				? await Component.getInitialProps( ctx, props.user, featureFlags )
				: {};

			return props;
		} catch ( error ) {
			console.error(error); // eslint-disable-line
			// Capture errors that happen during a page's getInitialProps.
			// This will work on both client and server sides.
			const [ , user ] = getInfoFromCookie( getCookieString( ctx.req ) );
			errorReporting.captureErrorInSentry( error, user );
			return { user: user };
		}
	}

	async componentDidMount() {
		let APP_ID = null;
		if ( window.location.hostname === 'app.rockpapercoin.com' ) {
			APP_ID = 'kv8guig4';
		} else {
			APP_ID = 'f7l9s40j';
		}

		const intercomSettings = {
			app_id: APP_ID,
			setData: true,
		};

		window.INTERCOM_APP_ID = APP_ID;

		if ( typeof window !== 'undefined' ) {
			window.Sentry = Sentry;
		}

		/* Intentionally don't wait for intercom here - let it run asynchronously - to
			allow ad blockers to access site quickly */
		const setUpTrackUser = async () => {
			await getIntercom();

			if ( this.props.user && window.Intercom && APP_ID ) {
				intercomSettings.setData = false;
				segmentIdentify( this.props.user );
				await segmentGroup( this.props.user );
				intercomData( this.props.user, intercomSettings );
			}
		};
		setUpTrackUser();

		window.ReactTooltip = ReactTooltip;
		ReactTooltip.hide();
		Router.events.on( 'routeChangeComplete', async ( url ) => {
			// The token here should cover when we redirect from a quickPayment to a guest invoice detail page
			const match = url.match( /^\/invoice\/[^?]+\?token=([^&]+)/ );
			const token = match ? match[ 1 ] : undefined;
			await trackPageView( url, this.props.user || { token } );
		} );

		this.setState( { isMounted: true } );
	}

	componentDidCatch( error, errorInfo ) {
		let user = null;
		if ( this.props?.user ) {
			user = this.props.user;
		}
		errorReporting.captureErrorInSentry( error, user, errorInfo );
		super.componentDidCatch( error, errorInfo );
	}

	componentDidUpdate() {
		ReactTooltip.rebuild();
		ReactTooltip.hide();
	}

	/**
	 * Determines whether or not a tooltip is display
	 * If it is an element that has CSS property of 'ellipsis' - tooltip displays based on length
	 * If no ellipsis property tooltip will always render
	 * @param  { Object } e - hover event object with tooltip properties
	 */
	toolTipDisplayControl( e ) {
		if ( e.target ) {
			// Element on which was hovered
			const target = e.target;
			// Needed styles for width measurement as overflow property caps ability to measure its container
			const textOverflow = window
				.getComputedStyle( target )
				.getPropertyValue( 'text-overflow' );
			const fontFamily = window
				.getComputedStyle( target )
				.getPropertyValue( 'font-family' );
			const fontSize = window
				.getComputedStyle( target )
				.getPropertyValue( 'font-size' );

			if ( textOverflow && textOverflow === 'ellipsis' ) {
				// Uses canvas API to measure text
				const canvas = document.createElement( 'canvas' );
				const ctx = canvas.getContext( '2d' );
				ctx.font = fontSize + ' ' + fontFamily;
				const textWidth = ctx.measureText( target.innerText );
				const targetBounds = target.getBoundingClientRect();
				// If text measurement is less than or equal to container width - do not show tooltip
				if ( textWidth.width <= targetBounds.width ) {
					ReactTooltip.hide();
				}

				canvas.remove();
			}
		}
	}

	render() {
		const { Component } = this.props;
		const googleScriptSource = `https://maps.googleapis.com/maps/api/js?key=${ process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY }&libraries=places`;

		/* When we come back from a 3D Secure verification we are in an iframe on the invoice detail page
		but we don't want to show that invoice detail page tiny in the iframe. So we show this spinner
		for the approx. 1 or 2 seconds it takes to update the backend on success or failure or whatnot. */
		const isReturningFrom3DSecureVerification =
			typeof window !== 'undefined'
				? /(payment|setup)_intent_client_secret/.test( window.location.href )
				: false;
		/* if we've redirected back from stripe 3D Secure verification then we're in
		an iframe and lets inform the parent of the payment intent client secret */
		if ( isReturningFrom3DSecureVerification && typeof window !== 'undefined' ) {
			const values = Object.fromEntries(
				new URLSearchParams(
					window.location.href.substring( window.location.href.indexOf( '?' ) + 1 )
				)
			);
			if ( typeof values.returnTo === 'string' ) {
				/* if we're in Chrome then cookies from outside the 3D Secure iframe are not included inside
				the iframe despite being served from the same domain, so we need to get the values we want
				from the returnTo URL */
				window.top?.postMessage( {
					...values,
					...Object.fromEntries(
						new URLSearchParams(
							values.returnTo.substring( values.returnTo.indexOf( '?' ) + 1 )
						)
					),
				} );
			} else {
				// Other browsers like Safari and Chrome have no such iframe cookie issue though
				window.top?.postMessage( values );
			}
		}

		return (
			<>
				<Head>
					<title>Rock Paper Coin</title>
					<meta
						name='viewport'
						content='width=device-width, initial-scale=1, minimum-scale=1'
					/>
					<link rel='icon' type='image/png' sizes='16x16' href={ favicon.src } />
				</Head>
				<Script
					src='https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/7.8.7/polyfill.min.js'
					integrity='sha256-025dcygmjSHGlBA5p7ahXH7XQU9g2+5y0iMdEayb2vM='
					crossOrigin='anonymous'
					async
				/>
				<Script src='https://js.stripe.com/v3/' async />
				<Script src={ analytics.src } async />
				<Script async defer src='https://apis.google.com/js/api.js' />
				<Script async defer src='https://accounts.google.com/gsi/client' />
				<Script src={ googleScriptSource } />
				<MuiXLicense />
				<ApolloContextProvider>
					<MuiThemeProvider>
						{ /* styled components here requires a work-around to combat hydration issues */ }
						{ this.state.isMounted && (
							<Provider store={ store }>
								<Page
									user={ this.props.user }
									featureFlags={ this.props.featureFlags }
								>
									{ isReturningFrom3DSecureVerification ? (
										<FullScreenSpinner />
									) : null }
									<Component { ...this.props.pageProps }></Component>
									<ReactTooltip
										id='tooltip'
										place='top'
										type='light'
										effect='solid'
										border
										textColor='rgb(32,58,96)'
										borderColor='rgb(32,58,96)'
										afterShow={ ( e ) => this.toolTipDisplayControl( e ) }
										html={ true }
									/>
								</Page>
							</Provider>
						) }
					</MuiThemeProvider>
				</ApolloContextProvider>
			</>
		);
	}
}

export default _app;

import React from 'react';
import { Typography } from '@mui/material';
import { DefaultEventProps, UserForContractPastDueEventFeed } from './helpers';
// Lib
import { getDetailLink, linkObject, toSimpleLink } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';
import { SignatureType } from '../../../types/generated';

type GetContractPastDueForCountersigningProps = {
	eventProps: DefaultEventProps;
	user: UserForContractPastDueEventFeed;
};

export const getContractPastDueForCounterSigningEventProps = ( {
	eventProps,
	user,
}: GetContractPastDueForCountersigningProps ) => {
	const { event } = eventProps;
	if ( !event.contract ) {
		throw new Error( `Contract missing on ContractPastDue event ${ event.id }` );
	}
	if ( !user || user.isLoggedIn === false ) {
		// user logged out and this component is re-rendering
		return;
	}

	eventProps.icon = 'contract-overdue';
	const counterSignerUser = event.contract?.signatures?.find(
		( signature ) => signature?.type === SignatureType.CounterSigner
	)?.signer;
	const counterSignerUserId = counterSignerUser?.orgUser?.user?.id;
	if ( !counterSignerUserId ) {
		throw new Error( 'No countersignature found' );
	}
	const userIsCounterSigner = counterSignerUserId === user.user.id;
	const userIsInternalToOrg =
		'organization' in user && user.organization?.id === event.vendor?.id;
	/* When you're signed in as the customer and looking at your own event feed, or looking at the
	event feed on the org detail page */
	if ( userIsCounterSigner ) {
		eventProps.message = (
			<Typography variant='body1'>
				The contract { linkObject( event.contract ) } is past due and requires a
				signature from you. Click{ ' ' }
				{ toSimpleLink( {
					text: 'here',
					link: getDetailLink( {
						object: event.contract,
						type: LinkObjectType.Contract,
					} ),
				} ) }{ ' ' }
				to take care of it.
			</Typography>
		);
		eventProps.links = [
			{
				type: 'primary',
				text: 'Sign',
				link: getDetailLink( {
					object: event.contract,
					type: LinkObjectType.Contract,
				} ),
			},
		];
		return eventProps;
	} else {
		const counterSignerName =
			counterSignerUser?.orgUser?.firstName +
			' ' +
			counterSignerUser?.orgUser?.lastName;
		eventProps.message = (
			<Typography variant='body1'>
				The contract { linkObject( event.contract ) } is past due and requires a
				signature from { counterSignerName }
				{ userIsInternalToOrg ? '' : ` at ${ event.vendor.name }` }. We've also
				reminded them.
			</Typography>
		);
		eventProps.links = [
			{
				type: 'secondary',
				text: 'View',
				link: getDetailLink( {
					object: event.contract,
					type: LinkObjectType.Contract,
				} ),
			},
		];
	}
	return eventProps;
};

import React from 'react';
import { LinkPropsType, toSimpleLink } from '../../../lib/helpers/index';
import { InvoiceAcceptedByPlannerEventPropsEventFragment } from './__generated__/getInstallmentPaymentRequestedByEventProps';
import { getPaymentInstallmentNames } from '../helpers';
import { EventCategory, UserType } from '../../../types/generated';
import { gql } from '@apollo/client';
import rpcShared from '@rockpapercoin/rpc-shared';
import { Typography } from '@mui/material';
import { FlattenedUser } from '../../../types/user';

export const invoiceAcceptedByPlannerEventPropsEventFragment = gql`
	fragment invoiceAcceptedByPlannerEventPropsEvent on Event {
		id
		category
		invoice {
			id
			title
			contact {
				id
				firstName
				lastName
				customer {
					id
					email
					clientUser {
						id
						assignedPlanner {
							id
							firstName
							lastName
							user {
								id
							}
							organization {
								id
							}
						}
					}
				}
				vendor {
					id
					name
				}
			}
			organization {
				id
			}
		}
		paymentInstallments {
			id
			name
		}
	}
`;

type SimpleLinkProps = {
	text: string;
	link: LinkPropsType;
};

export const getInstallmentPaymentRequestedByEventProps = (
	eventProps: {
		event: InvoiceAcceptedByPlannerEventPropsEventFragment;
		message: JSX.Element;
		links: {
			type: string;
			text: string;
			link: any;
			onClick?: ( () => any ) | null;
		}[];
		icon: JSX.Element | string;
		className: string;
	},
	user: FlattenedUser
) => {
	if ( !eventProps.event.invoice ) {
		throw new Error( `Event ID ${ eventProps.event.id } missing required data` );
	}

	const { invoice } = eventProps.event;

	if ( !invoice.contact || !eventProps.event.paymentInstallments ) {
		throw new Error( `Event ID ${ eventProps.event.id } missing required data` );
	}

	const orgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;

	const invoiceLinkProps: SimpleLinkProps = {
		text: invoice.title,
		link: {
			as: `/invoice/${ invoice.id }`, // @TODO add in guest token here?
			href: '/invoice/[id]',
		},
	};
	const invoiceLink = toSimpleLink( invoiceLinkProps );
	const customerLinkProps: SimpleLinkProps = {
		text: rpcShared.contacts.getCustomerName( invoice.contact ).fullName,
		link: {
			as: `/client/${ invoice.contact.customer.id }`,
			href: '/client/[id]',
		},
	};
	const plannerName = invoice?.contact?.customer.clientUser?.assignedPlanner
		? `${ invoice.contact.customer.clientUser.assignedPlanner.firstName } ${ invoice.contact.customer.clientUser.assignedPlanner.lastName }`
		: undefined;
	const viewerIsInInvoiceOrganization = orgUser
		? orgUser.organization.id === invoice.organization?.id
		: false;
	const viewerIsCustomer = user?.isLoggedIn
		? rpcShared.invoices.isCustomer( invoice, user.user.id )
		: false;
	const installmentNames = getPaymentInstallmentNames(
		eventProps.event.paymentInstallments
	);

	const defaultEventProps = {
		icon: 'dollar',
		links: [
			{
				type: 'secondary',
				text: 'View',
				link: invoiceLinkProps.link,
			},
		],
	};

	if (
		eventProps.event.category ===
		EventCategory.InstallmentPaymentRequestedByPlanner
	) {
		return {
			...defaultEventProps,
			message: (
				<Typography>
					{ plannerName } has paid { installmentNames } on { invoiceLink }.
				</Typography>
			),
		};
	} else {
		const customer = viewerIsCustomer ? (
			'You have'
		) : (
			<>
				{ viewerIsInInvoiceOrganization
					? toSimpleLink( customerLinkProps )
					: customerLinkProps.text }{ ' ' }
				has
			</>
		);
		return {
			...defaultEventProps,
			message: (
				<Typography>
					{ customer } paid { installmentNames } on { invoiceLink }.
				</Typography>
			),
		};
	}
};

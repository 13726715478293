import React from 'react';
import { LinkPropsType, toSimpleLink } from '../../../lib/helpers/index';
import { InstallmentPaymentRescheduledByVendorPropsEventFragment } from './__generated__/getInstallmentPaymentRescheduledByVendorProps';
import { gql } from '@apollo/client';
import rpcShared from '@rockpapercoin/rpc-shared';
import { Typography } from '@mui/material';
import { FlattenedUser } from '../../../types/user';

export const installmentPaymentRescheduledByVendorPropsEventFragment = gql`
	fragment installmentPaymentRescheduledByVendorPropsEvent on Event {
		id
		category
		invoice {
			id
			title
		}
		contact {
			id
			firstName
			lastName
			customer {
				id
				email
			}
		}
	}
`;

type SimpleLinkProps = {
	text: string;
	link: LinkPropsType;
};

export const getInstallmentPaymentRescheduledByVendorProps = (
	eventProps: {
		event: InstallmentPaymentRescheduledByVendorPropsEventFragment;
		message: JSX.Element;
		links: {
			type: string;
			text: string;
			link: any;
			onClick?: ( () => any ) | null;
		}[];
		icon: JSX.Element | string;
		className: string;
	},
	user: FlattenedUser
) => {
	if ( !eventProps.event.invoice || !eventProps.event.contact ) {
		throw new Error( `Event ID ${ eventProps.event.id } missing required data` );
	}

	const { invoice, contact } = eventProps.event;

	const invoiceLinkProps: SimpleLinkProps = {
		text: invoice.title,
		link: {
			as: `/invoice/${ invoice.id }`, // @TODO add in guest token here?
			href: '/invoice/[id]',
		},
	};
	const invoiceLink = toSimpleLink( invoiceLinkProps );
	const customerName = rpcShared.contacts.getCustomerName( contact ).fullName;
	const viewerIsCustomer = user?.isLoggedIn
		? rpcShared.invoices.isCustomer( invoice, user.user.id )
		: false;

	const defaultEventProps = {
		icon: 'warning',
		links: [
			{
				type: 'secondary',
				text: 'View',
				link: invoiceLinkProps.link,
			},
		],
	};

	if ( viewerIsCustomer ) {
		return {
			...defaultEventProps,
			message: (
				<Typography>
					Due dates have changed on your invoice { invoiceLink } that is scheduled
					for automatic payment.
				</Typography>
			),
		};
	} else {
		return {
			...defaultEventProps,
			message: (
				<Typography>
					Due dates have changed on { customerName }'s invoice { invoiceLink } that
					is scheduled for automatic payment.
				</Typography>
			),
		};
	}
};

import rpcShared from '@rockpapercoin/rpc-shared';
import { SignatureType, TeamMemberRole } from '../../../types/generated';
import {
	linkCustomer,
	LinkCustomerLinkType,
	LinkCustomerEvent,
} from '../helpers';

export interface UserForContractSentEventFeed {
	id: string;
	user?: {
		id: string;
	};
	organization?: {
		id: string;
		users: {
			id: string;
			role: TeamMemberRole;
		}[];
	};
}

export type DefaultEventProps = {
	event: EventForContractEventFeedHelpers;
	message: JSX.Element;
	links: ( { type: string; text: string; className?: string } & (
		| { link: any }
		| { onClick: () => void }
	) )[];
	icon: string;
	className: string;
};

/**
 * determines whether or not the user on the event is the actingUser on the event
 */
export const userIsActingUserOnEvent = (
	event: EventForContractEventFeedHelpers,
	user: UserForContractSentEventFeed
) => {
	return user?.user?.id === event.actingUser?.id;
};

export type EventForContractEventFeedHelpers = LinkCustomerEvent & {
	id: string;
	resolvedBy?: { id: string };
	vendor: { name: string; id: string };
	actingUser?: {
		id: string;
		orgUser?: {
			firstName: string;
			lastName: string;
			user: {
				id: string;
			};
		};
	};
	contract?: {
		id: string;
		sentByUser?: {
			user?: {
				id: string;
			};
		};
		signatures: {
			type: SignatureType;
			signer?: {
				orgUser?: {
					firstName: string;
					lastName: string;
					user: {
						id: string;
					};
				};
			};
		}[];
		vendor: { id: string };
	} & Parameters<
		typeof rpcShared.contracts.isAssignedPlannerOrAdminOfCustomersPlannerOrg
	>[0] &
	Parameters<typeof rpcShared.contracts.isCustomer>[0] &
	Parameters<typeof rpcShared.contracts.isAdminOrManagerOfVendor>[0] &
	Parameters<typeof rpcShared.contracts.isAssignedMember>[0];

	contacts?: ( Parameters<
		typeof rpcShared.contacts.isAssignedPlannerOrAdminOfCustomersPlannerOrg
	>[0] &
	Parameters<typeof rpcShared.contacts.getCustomerName>[0] )[];
};

export const userIsCustomerOnContractEvent = (
	event: EventForContractEventFeedHelpers,
	user: UserForContractSentEventFeed
) => {
	return (
		'contract' in event &&
		event.contract &&
		!!user?.user?.id &&
		rpcShared.contracts.isCustomer( event.contract, user?.user?.id )
	);
};

export const userIsSenderOnContractEvent = (
	event: EventForContractEventFeedHelpers,
	user: UserForContractSentEventFeed
) => {
	return (
		'contract' in event &&
		event.contract &&
		!!user?.user?.id &&
		event.contract?.sentByUser?.user?.id === user?.user?.id
	);
};

export const userIsAPPAOnContractEvent = (
	event: EventForContractEventFeedHelpers,
	user: UserForContractSentEventFeed
) => {
	return (
		'contract' in event &&
		event.contract &&
		!!user?.user?.id &&
		rpcShared.contracts.isAssignedPlannerOrAdminOfCustomersPlannerOrg(
			event.contract,
			user
		)
	);
};

export const userIsAssignedMemberOrVendorAdminOnContractEvent = (
	event: EventForContractEventFeedHelpers,
	user: UserForContractSentEventFeed
) => {
	return (
		'contract' in event &&
		event.contract &&
		!!user?.user?.id &&
		( rpcShared.contracts.isAdminOrManagerOfVendor( event.contract, user ) ||
			rpcShared.contracts.isAssignedMember( event.contract, user ) )
	);
};

export const getCustomerLinksAsVendorOrAPPAForContractEvent = (
	event: EventForContractEventFeedHelpers,
	user: UserForContractSentEventFeed,
	asAPPA: boolean
) => {
	if ( !event?.contacts?.length ) {
		return null;
	}
	const contactsToRender = asAPPA
		? event.contacts.filter( ( contact ) =>
			rpcShared.contacts.isAssignedPlannerOrAdminOfCustomersPlannerOrg(
				contact,
				user
			)
		  )
		: event.contacts;
	if ( !contactsToRender?.length ) {
		return null;
	}
	const shouldRenderAmpersand = contactsToRender.length === 2;

	const firstContact = linkCustomer(
		{ contact: contactsToRender[ 0 ] },
		LinkCustomerLinkType.Contact
	);

	const secondContact =
		shouldRenderAmpersand &&
		linkCustomer(
			{ contact: contactsToRender[ 1 ] },
			LinkCustomerLinkType.Contact
		);

	return (
		<>
			{ firstContact }
			{ shouldRenderAmpersand ? ' and ' : '' }
			{ secondContact ? secondContact : '' }
		</>
	);
};

export const getCustomerNamesAsNonLinkComponent = (
	event: EventForContractEventFeedHelpers
) => {
	if ( !event?.contacts?.length ) {
		return null;
	}
	const shouldRenderAmpersand = event.contacts.length === 2;

	const { fullName: firstContact } = rpcShared.contacts.getCustomerName(
		event.contacts[ 0 ]
	);

	const secondContactRes = shouldRenderAmpersand
		? rpcShared.contacts.getCustomerName( event.contacts[ 1 ] )
		: undefined;

	const secondContact = secondContactRes?.fullName;

	return (
		<>
			{ firstContact }
			{ shouldRenderAmpersand ? ' and ' : '' }
			{ secondContact ? secondContact : '' }
		</>
	);
};

export type UserForContractPastDueEventFeed =
	| undefined
	| { isLoggedIn: false }
	| {
		isLoggedIn: true;
		id: string;
		user: {
			id: string;
		};
		organization: {
			id: string;
			users: {
				id: string;
				role: TeamMemberRole;
			}[];
		};
	  }
	| {
		isLoggedIn: true;
		id: string;
		user: {
			id: string;
		};
		assignedPlanner?: {
			id: string;
			users: {
				role: TeamMemberRole;
				organization: {
					id: string;
					users: {
						id: string;
						role: TeamMemberRole;
					}[];
				};
			};
		};
	  };

import React from 'react';
import { Typography } from '@mui/material';
import { DefaultEventProps, UserForContractPastDueEventFeed } from './helpers';
// Lib
import { getDetailLink, linkObject } from '../../../lib/helpers';
import { LinkObjectType } from '../../../lib/helpers/types';
import rpcShared from '@rockpapercoin/rpc-shared';

type GetContractPastDueEventPropsProps = {
	eventProps: DefaultEventProps;
	user: UserForContractPastDueEventFeed;
};

const getContractPastDueEventProps = ( {
	eventProps,
	user,
}: GetContractPastDueEventPropsProps ) => {
	const { event } = eventProps;
	if ( !event.contract ) {
		throw new Error( `Contract missing on ContractPastDue event ${ event.id }` );
	}
	if ( !user || user.isLoggedIn === false ) {
		// user logged out and this component is re-rendering
		return;
	}

	eventProps.icon = 'contract-overdue';

	/* When you're signed in as the customer and looking at your own event feed, or looking at the
	event feed on the org detail page */
	if ( rpcShared.contracts.isAdminOrManagerOfVendor( event.contract, user ) ) {
		eventProps.message = (
			<Typography variant='body1'>
				{ linkObject( event.contract ) } is past due. Update and resend?
			</Typography>
		);
		eventProps.links = [
			{
				type: 'secondary',
				text: 'Update',
				link: getDetailLink( {
					object: event.contract,
					type: LinkObjectType.Contract,
				} ),
			},
		];
		return eventProps;
	} else {
		const viewingUserIsMemberOfVendor =
			user.isLoggedIn &&
			'organization' in user &&
			event.vendor.id === user.organization?.id;
		eventProps.message = viewingUserIsMemberOfVendor ? (
			<Typography variant='body1'>
				{ linkObject( event.contract ) } is past due.
			</Typography>
		) : (
			<Typography variant='body1'>
				{ linkObject( event.contract ) } from { linkObject( event.vendor ) } is past{ ' ' }
				due.
			</Typography>
		);
		eventProps.links = [
			{
				type: 'secondary',
				text: 'View',
				link: getDetailLink( {
					object: event.contract,
					type: LinkObjectType.Contract,
				} ),
			},
		];
		return eventProps;
	}
};

export default getContractPastDueEventProps;

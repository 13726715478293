import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
export type GetProposalPastDueEventPropsEventFragment = { __typename?: 'Event', category: Types.EventCategory, resolvedBy?: { __typename?: 'Event', id: string } | null, proposal?: { __typename?: 'Proposal', id: string, title?: string | null, sentByUser?: { __typename?: 'OrgUser', id: string } | null, contact: { __typename?: 'Contact', firstName: string, lastName: string, customer: { __typename?: 'User', id: string, email: string, clientUser?: { __typename?: 'ClientUser', assignedPlanner?: { __typename?: 'OrgUser', id: string, organization?: { __typename?: 'Organization', id: string } | null } | null } | null }, assignedMember: { __typename?: 'OrgUser', id: string }, vendor: { __typename?: 'Organization', id: string, name: string, users?: Array<{ __typename?: 'OrgUser', id: string, role: Types.TeamMemberRole }> | null } } } | null };

export const GetProposalPastDueEventPropsEventFragmentDoc = gql`
    fragment getProposalPastDueEventPropsEvent on Event {
  category
  resolvedBy {
    id
  }
  proposal {
    id
    title
    sentByUser {
      id
    }
    contact {
      firstName
      lastName
      customer {
        id
        email
        clientUser {
          assignedPlanner {
            id
            organization {
              id
            }
          }
        }
      }
      assignedMember {
        id
      }
      vendor {
        id
        name
        users {
          id
          role
        }
      }
    }
  }
}
    `;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    
export const ProposalContactFragment = [
	'id',
	'firstName',
	'lastName',
	'eventDate',
	{
		assignedMember: [
			'id',
			{
				organization: [
					'id',
					'name',
					'image',
					{
						services: [ 'id', 'name' ],
					},
				],
			},
		],
	},
	{
		customer: [
			'id',
			'email',
			{
				clientUser: [
					'id',
					{
						assignedPlanner: [
							'id',
							{
								organization: [
									'id',
									{
										users: [ 'id', 'role' ],
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{
		vendor: [
			'id',
			'name',
			'image',
			{ users: [ 'id', 'role' ] },
			{
				services: [ 'id', 'name' ],
			},
		],
	},
];

export const ProposalFragment = [
	'id',
	'createdAt',
	'updatedAt',
	'dueDate',
	'eventDate',
	'status',
	'title',
	'isPastDue',
	{ contact: ProposalContactFragment },
];

export const ProposalContactVendorFragment = [ 'id', 'name' ];

export const ProposalWithContactFragment = [ ...ProposalFragment, { contact: ProposalContactFragment }, ];

export const CreateProposalReturnFields = [
	'source',
	'thumbnail',
	...ProposalWithContactFragment,
];

export const GetProposalWhereReturnFields = [
	'sentAt',
	'source',
	'thumbnail',
	...ProposalWithContactFragment,
];

export const GetProposalsWhereReturnFields = [
	...ProposalFragment,
	'sentAt',
	'sentByUsername',
	{
		contact: [ ...ProposalContactFragment, { vendor: ProposalContactVendorFragment }, ],
	},
];

export const VoidProposalReturnFields = [ 'id' ];
export const AcceptProposalReturnFields = [ 'id', 'status' ];

export const DeleteProposalReturnFields = [ 'id' ];

export const SendProposalReturnFields = [
	...ProposalFragment,
	'sentAt',
	'sentByUsername',
	{
		contact: [ ...ProposalContactFragment, { vendor: ProposalContactVendorFragment }, ],
	},
];

export const ProposalTemplateFragment = [
	'id',
	'createdAt',
	'title',
	'status',
	'thumbnail',
];

export const CreateProposalTemplateReturnFields = [ ...ProposalTemplateFragment ];

export const GetProposalTemplatesWhereReturnFields = [
	...ProposalTemplateFragment,
	{
		proposals: [ 'id' ],
	},
];

export const GetProposalTemplateWhereReturnFields = [ ...ProposalTemplateFragment, 'source', ];

export const DeleteProposalTemplateFields = [ 'id' ];

export const DeleteProposaleFields = [ 'id' ];

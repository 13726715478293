import React from 'react';
import MuiModal from '../../mui/MuiModal/MuiModal';
import Link from 'next/link';
import styles from './PremiumSubscriptionRequiredModal.module.scss';
import { Typography } from '@mui/material';
import { FlattenedUser } from '../../types/user';
import { useSelector } from 'react-redux';
import rpc from '@rockpapercoin/rpc-shared';
import { UserType } from '../../types/pages';
import MuiButton from '../../mui/MuiButton';

export type PremiumSubscriptionRequiredModalProps = {
	open: boolean;
	onClose: () => void;
};

export const PremiumSubscriptionRequiredModal: React.FC<
PremiumSubscriptionRequiredModalProps
> = ( { open, onClose } ) => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const orgUser =
		user?.isLoggedIn && user.userType === UserType.OrgUser ? user : undefined;
	const isAdmin = orgUser
		? rpc.organizations.isAdmin( orgUser.organization, orgUser )
		: false;
	return (
		<MuiModal
			controls={
				!isAdmin ? <MuiButton onClick={ onClose }>Got it</MuiButton> : undefined
			}
			muiModalProps={ {
				open,
				onClose,
				PaperProps: { 'data-cy': 'premium-subscription-required' },
			} }
			title='Premium subscription required'
			description={
				<>
					{ isAdmin ? (
						<Typography component='span' className={ styles.paragraph }>
							You can purchase a Premium subscription on the{ ' ' }
							<Link
								className='legacyLink'
								href='/admin?tab=billing'
								onClick={ onClose }
							>
								Billing tab of your admin page
							</Link>
							, or get in touch with one of our team members for assistance.
						</Typography>
					) : (
						<Typography component='span' className={ styles.paragraph }>
							Hey, it looks like you're trying to access leads! This requires a
							premium subscription which can be turned on by an admin at your
							organization.
						</Typography>
					) }
				</>
			}
		/>
	);
};

import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetInvoicePastDueEventProps_SendOrResendInvoiceMutationVariables = Types.Exact<{
  data?: Types.InputMaybe<Types.SendOrResendInvoiceInput>;
  where: Types.InvoiceWhereUniqueInput;
}>;


export type GetInvoicePastDueEventProps_SendOrResendInvoiceMutation = { __typename?: 'Mutation', sendOrResendInvoice: { __typename?: 'Invoice', id: string } };

export type GetInvoicePastDueEventPropsEventFragment = { __typename?: 'Event', id: string, category: Types.EventCategory, invoice?: { __typename?: 'Invoice', id: string, title: string, contact?: { __typename?: 'Contact', id: string, firstName: string, lastName: string, customer: { __typename?: 'User', id: string, email: string, clientUser?: { __typename?: 'ClientUser', id: string, assignedPlanner?: { __typename?: 'OrgUser', id: string, firstName: string, lastName: string, user?: { __typename?: 'User', id: string } | null, organization?: { __typename?: 'Organization', id: string } | null } | null } | null }, vendor: { __typename?: 'Organization', id: string, name: string }, assignedMember: { __typename?: 'OrgUser', id: string, user?: { __typename?: 'User', id: string } | null } } | null, organization?: { __typename?: 'Organization', id: string } | null } | null, resolvedBy?: { __typename?: 'Event', id: string } | null };

export const GetInvoicePastDueEventPropsEventFragmentDoc = gql`
    fragment getInvoicePastDueEventPropsEvent on Event {
  id
  category
  invoice {
    id
    title
    contact {
      id
      firstName
      lastName
      customer {
        id
        email
        clientUser {
          id
          assignedPlanner {
            id
            firstName
            lastName
            user {
              id
            }
            organization {
              id
            }
          }
        }
      }
      vendor {
        id
        name
      }
      assignedMember {
        id
        user {
          id
        }
      }
    }
    organization {
      id
    }
  }
  resolvedBy {
    id
  }
}
    `;
export const GetInvoicePastDueEventProps_SendOrResendInvoiceDocument = gql`
    mutation GetInvoicePastDueEventProps_SendOrResendInvoice($data: SendOrResendInvoiceInput, $where: InvoiceWhereUniqueInput!) {
  sendOrResendInvoice(data: $data, where: $where) {
    id
  }
}
    `;
export type GetInvoicePastDueEventProps_SendOrResendInvoiceMutationFn = Apollo.MutationFunction<GetInvoicePastDueEventProps_SendOrResendInvoiceMutation, GetInvoicePastDueEventProps_SendOrResendInvoiceMutationVariables>;

/**
 * __useGetInvoicePastDueEventProps_SendOrResendInvoiceMutation__
 *
 * To run a mutation, you first call `useGetInvoicePastDueEventProps_SendOrResendInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePastDueEventProps_SendOrResendInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getInvoicePastDueEventPropsSendOrResendInvoiceMutation, { data, loading, error }] = useGetInvoicePastDueEventProps_SendOrResendInvoiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInvoicePastDueEventProps_SendOrResendInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<GetInvoicePastDueEventProps_SendOrResendInvoiceMutation, GetInvoicePastDueEventProps_SendOrResendInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetInvoicePastDueEventProps_SendOrResendInvoiceMutation, GetInvoicePastDueEventProps_SendOrResendInvoiceMutationVariables>(GetInvoicePastDueEventProps_SendOrResendInvoiceDocument, options);
      }
export type GetInvoicePastDueEventProps_SendOrResendInvoiceMutationHookResult = ReturnType<typeof useGetInvoicePastDueEventProps_SendOrResendInvoiceMutation>;
export type GetInvoicePastDueEventProps_SendOrResendInvoiceMutationResult = Apollo.MutationResult<GetInvoicePastDueEventProps_SendOrResendInvoiceMutation>;
export type GetInvoicePastDueEventProps_SendOrResendInvoiceMutationOptions = Apollo.BaseMutationOptions<GetInvoicePastDueEventProps_SendOrResendInvoiceMutation, GetInvoicePastDueEventProps_SendOrResendInvoiceMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    
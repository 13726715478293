import React, { SVGProps } from 'react';

/* eslint max-len: 0 */
const ContractSigned = ( props: SVGProps<SVGSVGElement> ) => (
	<svg viewBox='0 0 24 24' { ...props }>
		<path
			d='M10.668-.002c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H6.515l-.38.35c-.29.269-.532.516-.762.785C4.477 3.68 3.97 4.839 3.97 6.234l.006 2.31.034 5.166v.713c-.006 5.266-.265 6.109-1.536 7.595-.096.113-.2.227-.31.343l-.135.137h15.88l1.023-1.02c1.288-1.289 1.573-2.268 1.565-5.37l-.026-2.254-.001-.354c0-.414.336-.75.75-.75s.75.336.75.75l.004.703.021 1.592c.027 3.63-.284 4.963-1.852 6.59l-.15.154-1.136 1.134c-.181.179-.416.29-.667.318l-.126.007H1.125c-.455 0-.865-.274-1.04-.695-.156-.378-.094-.807.155-1.124l.09-.101.6-.6c.102-.103.195-.2.281-.295l.123-.14c.942-1.101 1.165-1.82 1.177-6.384v-.989l-.002-.355-.035-5.23C2.47 7.472 2.47 6.87 2.47 6.235c0-1.788.651-3.276 1.763-4.576.204-.238.412-.458.642-.682l.347-.327c.156-.142.415-.378.468-.431.112-.113.257-.186.412-.21l.118-.01h4.448zm6.74.75c.524 0 1.028.185 1.426.519l.144.132.58.578c1.064-.62 2.436-.525 3.409.305l.172.158c1.148 1.156 1.148 3.021-.002 4.178l-3.11 3.11-.009.01-.009.007-5.19 5.19c-.266.267-.683.29-.976.073l-.084-.073-.028-.029c-.036.746-.343 1.479-.917 2.035-.463.464-1.346.842-2.605 1.216-.556.165-1.149.317-1.741.452-.118.027-.228.052-.328.073l-.467.097c-.521.101-.98-.357-.878-.88.031-.16.09-.438.17-.793l.141-.59c.098-.393.202-.78.313-1.15.374-1.259.752-2.14 1.206-2.595.56-.579 1.297-.889 2.045-.925l-.03-.03c-.293-.293-.293-.767 0-1.06l7.786-7.788-.508-.509c-.135-.135-.319-.21-.51-.21-.152 0-.3.048-.422.137l-.087.073L13.26 6.1c-.293.292-.767.292-1.06 0-.267-.267-.29-.684-.073-.977l.073-.084 3.639-3.64c.416-.416.98-.65 1.57-.65zm-5.661 12.905c-.617-.465-1.496-.404-2.053.17-.22.222-.536.959-.838 1.97-.155.522-.3 1.085-.428 1.647l.091-.386.454-.11.275-.073.534-.152c1.012-.3 1.75-.616 1.98-.847.603-.585.63-1.544.061-2.163l.017.021zm4.621-6.505l-4.137 4.138 2.058 2.058 4.137-4.138-2.058-2.058zm5.507-3.816c-.57-.393-1.354-.333-1.857.167.003-.002.002 0 0 0l-.007.006-2.582 2.582 2.058 2.058 2.588-2.586c.496-.499.558-1.266.202-1.817l-.083-.115-.095-.106-.109-.101z'
			transform='translate(-584 -1020) translate(144 183) translate(0 783) translate(0 53) translate(440 1)'
		/>
	</svg>
);

export default ContractSigned;

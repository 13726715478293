import { useRouter } from 'next/router';
import React from 'react';
import {
	isAssignedPlannerOrAdmin,
	toSimpleLink,
} from '../../../lib/helpers/index';
import { ProposalsIcon } from '../../../mui/Icons/index';
import { linkCustomer, LinkCustomerLinkType } from '../helpers';
import { EventCategory } from '../../../types/generated';
import { gql } from '@apollo/client';
import { ProposalAcceptedByPlannerEventPropsEventFragment } from './__generated__/getProposalAcceptedEventProps';
import { Typography } from '@mui/material';
import { FlattenedUser } from '../../../types/user';

export const proposalAcceptedByPlannerEventPropsEventFragment = gql`
	fragment proposalAcceptedByPlannerEventPropsEvent on Event {
		category
		proposal {
			id
			title
			contact {
				firstName
				lastName
				customer {
					id
					email
					clientUser {
						assignedPlanner {
							id
							organization {
								id
							}
						}
					}
				}
				vendor {
					id
					name
				}
			}
		}
	}
`;

export default function getProposalAcceptedByPlannerEventProps(
	defaultEventProps: {
		event: ProposalAcceptedByPlannerEventPropsEventFragment;
		message: JSX.Element;
		links: {
			type: string;
			text: string;
			link: any;
			onClick?: ( () => any ) | null;
		}[];
		icon: JSX.Element | string;
		className: string;
	},
	user: FlattenedUser
) {
	// if a file isn't a component and it uses a hook then its name should start with `use`
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const router = useRouter();
	const updatedEventProps = { ...defaultEventProps };
	const proposalLink = {
		text: updatedEventProps.event.proposal?.title,
		link: {
			as: `/proposal/${ updatedEventProps.event.proposal?.id }`,
			href: '/proposal/[id]',
		},
	};
	const customerLink = updatedEventProps.event.proposal
		? linkCustomer(
			{ contact: updatedEventProps.event.proposal.contact },
			LinkCustomerLinkType.Contact
		  )
		: undefined;
	const vendorLink = {
		text: updatedEventProps.event.proposal?.contact?.vendor.name,
		link: {
			as: `/org/${ updatedEventProps.event.proposal?.contact?.vendor.id }`,
			href: '/org/[id]',
		},
	};
	const assignedPlannerOrAdmin = updatedEventProps.event.proposal?.contact
		?.customer.clientUser
		? isAssignedPlannerOrAdmin(
			user,
			updatedEventProps.event.proposal.contact.customer.clientUser
		  )
		: false;
	const isVendorDetail = router.pathname === '/org/[id]';

	updatedEventProps.icon = <ProposalsIcon />;
	updatedEventProps.links = [
		{
			type: 'secondary',
			text: 'View',
			link: proposalLink.link,
		},
	];

	if (
		defaultEventProps.event.category === EventCategory.ProposalAcceptedByPlanner
	) {
		if ( isVendorDetail ) {
			updatedEventProps.message = (
				<Typography>{ toSimpleLink( proposalLink ) } has been accepted.</Typography>
			);
		} else {
			updatedEventProps.message = (
				<Typography>
					{ customerLink } has accepted a proposal from { toSimpleLink( vendorLink ) }
				</Typography>
			);
		}
	} else if (
		defaultEventProps.event.category ===
		EventCategory.ProposalAcceptedByCustomer
	) {
		if ( assignedPlannerOrAdmin ) {
			updatedEventProps.message = (
				<Typography>
					{ customerLink } has accepted a proposal from { toSimpleLink( vendorLink ) }
				</Typography>
			);
		} else {
			updatedEventProps.message = (
				<Typography>{ toSimpleLink( proposalLink ) } has been accepted.</Typography>
			);
		}
	}

	return updatedEventProps;
}

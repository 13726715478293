import { useDispatch, useSelector } from 'react-redux';
import { TeamMemberRole, UserType } from '../../../types/generated';
import { gql } from '@apollo/client';
import {
	OrgOnboarding_CreateOrgUserMutation,
	OrgOnboarding_UpdateOrgUserMutation,
	useOrgOnboarding_CreateOrgUserMutation,
	useOrgOnboarding_LoginLazyQuery,
	useOrgOnboarding_UpdateOrgUserMutation,
} from './__generated__/useYourInfo';
import validations from '../../../lib/helpers/validations';
import { showError } from '../../Toast';
import { login as reduxLogin } from '../../../redux/actions';
import { segmentIdentify } from '../../../lib/helpers/segment';
import { useState, useRef, useCallback } from 'react';
import { FlattenedUser, UserRegistration } from '../../../types/user';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createOrgUserMutation = gql`
	mutation OrgOnboarding_createOrgUser($data: OrgUserCreationInput!) {
		createOrgUser(data: $data) {
			id
			firstName
			lastName
			user {
				id
				email
				userType
			}
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateOrgUserMutation = gql`
	mutation OrgOnboarding_updateOrgUser(
		$data: OrgUserUpdateCustomInput!
		$where: OrgUserWhereUniqueInput!
	) {
		updateOrgUser(data: $data, where: $where) {
			id
			firstName
			lastName
			user {
				id
				email
				userType
			}
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loginQuery = gql`
	query OrgOnboarding_login($data: UserLoginInput!) {
		login(data: $data) {
			token
		}
	}
`;

const useYourInfo = () => {
	const user: FlattenedUser = useSelector( ( state: any ) => state?.user );
	const userRegistration: UserRegistration = useSelector(
		( state: any ) => state?.userRegistration
	);
	const loggedInUser = user?.isLoggedIn ? user : undefined;
	const dispatch = useDispatch();
	// all of the state for the children
	const [ firstName, setFirstName ] = useState(
		user?.isLoggedIn && user.userType === UserType.OrgUser
			? user.firstName
			: undefined
	);
	const [ lastName, setLastName ] = useState(
		user?.isLoggedIn && user.userType === UserType.OrgUser
			? user.lastName
			: undefined
	);
	const [ firstNameValidation, setFirstNameValidation ] = useState<
	string | undefined
	>();
	const [ lastNameValidation, setLastNameValidation ] = useState<
	string | undefined
	>();
	const onBlurValidationEnabledRef = useRef( false );
	const onBlurValidationEnabled = onBlurValidationEnabledRef.current;

	// API calls
	const [ createOrgUser ] = useOrgOnboarding_CreateOrgUserMutation();
	const [ updateOrgUser ] = useOrgOnboarding_UpdateOrgUserMutation();
	const [ login ] = useOrgOnboarding_LoginLazyQuery();

	const onValidate = useCallback(
		( force = false ) => {
			const orgNameErrors = validations.validateOrgUserName(
				firstName,
				lastName
			);
			if ( onBlurValidationEnabled || force === true ) {
				setFirstNameValidation( orgNameErrors.firstName );
				setLastNameValidation( orgNameErrors.lastName );
			}
			if (
				typeof orgNameErrors.firstName === 'string' ||
				typeof orgNameErrors.lastName === 'string' ||
				!firstName ||
				!lastName
			) {
				return false;
			} else {
				return true;
			}
		},
		[
			firstName,
			lastName,
			onBlurValidationEnabled
		]
	);

	const onNext = useCallback( async () => {
		onBlurValidationEnabledRef.current = true;
		const validationResult = onValidate( true );
		if ( !validationResult ) {
			return false;
		}

		// A helper just so we do the same thing after a create and an update
		const handleSuccessfulAction = async (
			user: NonNullable<
			| OrgOnboarding_CreateOrgUserMutation['createOrgUser']
			| OrgOnboarding_UpdateOrgUserMutation['updateOrgUser']
			>
		) => {
			segmentIdentify( user );
			return true;
		};

		// Do either the create or the update
		if ( loggedInUser ) {
			const response = await updateOrgUser( {
				variables: {
					data: {
						firstName,
						lastName,
					},
					where: {
						id: loggedInUser.id,
					},
				},
			} );
			if ( response.errors || !response.data?.updateOrgUser ) {
				response.errors?.forEach( ( error ) => showError( error ) );
				return false;
			} else {
				return await handleSuccessfulAction( response.data.updateOrgUser );
			}
		} else {
			const email = userRegistration
				? userRegistration.coreUserFields?.email
				: undefined;
			const password = userRegistration
				? userRegistration.coreUserFields?.password
				: undefined;
			if ( !email || !password || !firstName || !lastName ) {
				return false;
			}
			const response = await createOrgUser( {
				variables: {
					data: {
						email,
						password,
						firstName,
						lastName,
						role: userRegistration?.invitation?.id
							? TeamMemberRole.Member
							: TeamMemberRole.Owner,
						...( userRegistration?.verificationToken?.id
							? { verificationTokenID: userRegistration.verificationToken.id }
							: undefined ),
						...( userRegistration?.invitation?.id
							? { invitationID: userRegistration.invitation.id }
							: undefined ),
					},
				},
			} );
			if ( response.errors || !response.data?.createOrgUser ) {
				response.errors?.forEach( ( error ) => showError( error ) );
				return false;
			} else {
				const loginResponse = await login( {
					variables: {
						data: {
							email,
							password,
						},
					},
				} );
				if ( loginResponse.error ) {
					showError( loginResponse.error );
					return false;
				} else if ( loginResponse.data?.login ) {
					dispatch(
						reduxLogin(
							response.data?.createOrgUser,
							loginResponse.data.login.token
						)
					);
					return await handleSuccessfulAction( response.data.createOrgUser );
				} else {
					return false;
				}
			}
		}
	}, [
		createOrgUser,
		dispatch,
		firstName,
		lastName,
		loggedInUser,
		login,
		onValidate,
		updateOrgUser,
		userRegistration,
	] );

	const onBack = useCallback( () => {
		onBlurValidationEnabledRef.current = false;
	}, [] );

	return {
		firstName,
		setFirstName,
		firstNameValidation,
		lastName,
		setLastName,
		lastNameValidation,
		onNext,
		onBack,
		onValidate,
	};
};

export default useYourInfo;

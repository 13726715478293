import React, { useCallback } from 'react';
import { LinkPropsType, toSimpleLink } from '../../../lib/helpers/index';
import {
	useGetInvoicePastDueEventProps_SendOrResendInvoiceMutation,
	GetInvoicePastDueEventPropsEventFragment,
} from './__generated__/getInvoicePastDueEventProps';
import { UserType } from '../../../types/generated';
import { gql } from '@apollo/client';
import rpcShared from '@rockpapercoin/rpc-shared';
import { Typography } from '@mui/material';
import { FlattenedUser } from '../../../types/user';
import { displayStripeNeedsModal } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import { showError } from '../../Toast';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sendOrResendInvoiceMutation = gql`
	mutation GetInvoicePastDueEventProps_SendOrResendInvoice(
		$data: SendOrResendInvoiceInput
		$where: InvoiceWhereUniqueInput!
	) {
		sendOrResendInvoice(data: $data, where: $where) {
			id
		}
	}
`;

export const getInvoicePastDueEventPropsEventFragment = gql`
	fragment getInvoicePastDueEventPropsEvent on Event {
		id
		category
		invoice {
			id
			title
			contact {
				id
				firstName
				lastName
				customer {
					id
					email
					clientUser {
						id
						assignedPlanner {
							id
							firstName
							lastName
							user {
								id
							}
							organization {
								id
							}
						}
					}
				}
				vendor {
					id
					name
				}
				assignedMember {
					id
					user {
						id
					}
				}
			}
			organization {
				id
			}
		}
		resolvedBy {
			id
		}
	}
`;

type SimpleLinkProps = {
	text: string;
	link: LinkPropsType;
};

/* can't use useDispatch without a hook, and can't use a hook in a non-hook/non-component
so that's why this hook returns a function */
export const useGetInvoicePastDueEventProps = ( refresh?: () => void ) => {
	const dispatch = useDispatch();
	const [ sendOrResendInvoice ] =
		useGetInvoicePastDueEventProps_SendOrResendInvoiceMutation();

	const resendInvoice = useCallback(
		async ( invoiceId: string ) => {
			const response = await sendOrResendInvoice( {
				variables: {
					where: { id: invoiceId },
				},
			} );
			if ( response.errors ) {
				if (
					response.errors.some( ( error ) =>
						error.message.includes(
							rpcShared.strings.errorMessages.hasStripeNeeds
						)
					)
				) {
					dispatch(
						displayStripeNeedsModal( { active: true, props: { purpose: null } } )
					);
				} else {
					response.errors.map( ( error ) => showError( error ) );
				}
			} else if ( refresh ) {
				refresh();
			}
		},
		[
			dispatch,
			sendOrResendInvoice,
			refresh
		]
	);

	const getInvoicePastDueEventProps = useCallback(
		(
			eventProps: {
				event: GetInvoicePastDueEventPropsEventFragment;
				message: JSX.Element;
				links: {
					type: string;
					text: string;
					link: any;
					onClick?: ( () => any ) | null;
				}[];
				icon: JSX.Element | string;
				className: string;
			},
			user: FlattenedUser
		) => {
			if ( !eventProps.event.invoice ) {
				throw new Error(
					`Event ID ${ eventProps.event.id } missing required data`
				);
			}

			const { invoice } = eventProps.event;

			if ( !invoice.contact ) {
				throw new Error(
					`Event ID ${ eventProps.event.id } missing required data`
				);
			}

			const orgUser =
				user?.isLoggedIn && user.userType === UserType.OrgUser
					? user
					: undefined;

			const invoiceLinkProps: SimpleLinkProps = {
				text: invoice.title,
				link: {
					as: `/invoice/${ invoice.id }`, // @TODO add in guest token here?
					href: '/invoice/[id]',
				},
			};
			const invoiceLink = toSimpleLink( invoiceLinkProps );
			const customerLinkProps: SimpleLinkProps = {
				text: rpcShared.contacts.getCustomerName( invoice.contact ).fullName,
				link: {
					as: `/client/${ invoice.contact.customer.id }`,
					href: '/client/[id]',
				},
			};
			const vendorLink = {
				text: invoice.contact.vendor.name,
				link: {
					as: `/org/${ invoice.contact.vendor.id }`,
					href: '/org/[id]',
				},
			};
			const viewerIsInInvoiceOrganization = orgUser
				? orgUser.organization.id === invoice.organization?.id
				: false;
			const viewerIsAssignedPlannerOrAdmin =
				!viewerIsInInvoiceOrganization && // they're not from the invoice organization
				invoice?.contact?.customer.clientUser &&
				orgUser // and they're the customer's planner
					? rpcShared.invoices.isAssignedPlannerOrAdminOfCustomersPlannerOrg(
						invoice,
						orgUser
					  )
					: false;
			const viewerIsAdminOrManagerInInvoiceOrganization =
				orgUser && viewerIsInInvoiceOrganization
					? rpcShared.organizations.isAdminOrManager(
						orgUser.organization,
						orgUser
					  )
					: false;
			const viewerIsAssignedMember =
				orgUser && viewerIsInInvoiceOrganization
					? rpcShared.invoices.isAssignedMember( invoice, orgUser )
					: false;
			const viewerIsCustomer = user?.isLoggedIn
				? rpcShared.invoices.isCustomer( invoice, user.user.id )
				: false;

			const defaultEventProps = {
				icon: 'invoice-due',
				links: [
					{
						type: 'secondary',
						text: 'View',
						link: invoiceLinkProps.link,
					},
				],
			};

			if ( viewerIsCustomer ) {
				return {
					...defaultEventProps,
					message: (
						<Typography>
							{ invoiceLink } from { toSimpleLink( vendorLink ) } is past due.
						</Typography>
					),
				};
			} else if ( viewerIsAssignedPlannerOrAdmin ) {
				/* this only matches if the viewer is the customers planner, and also not
				from the same organization that sent this invoice */
				return {
					...defaultEventProps,
					message: (
						<Typography>
							An invoice from { toSimpleLink( vendorLink ) } that was sent to{ ' ' }
							{ toSimpleLink( customerLinkProps ) } is past due: { invoiceLink }.
						</Typography>
					),
				};
			} else if (
				viewerIsInInvoiceOrganization ||
				viewerIsAdminOrManagerInInvoiceOrganization ||
				viewerIsAssignedMember
			) {
				const invoiceId = eventProps.event.invoice.id;
				return {
					...defaultEventProps,
					links: [
						eventProps.event.resolvedBy ||
						!viewerIsAdminOrManagerInInvoiceOrganization
							? defaultEventProps.links
							: {
								type: 'primary',
								text: 'Resend',
								onClick: () => resendInvoice( invoiceId ),
							  },
					],
					message: <Typography>{ invoiceLink } is past due. Resend?</Typography>,
				};
			} else {
				return {
					...defaultEventProps,
					message: <Typography>{ invoiceLink } is past due.</Typography>,
				};
			}
		},
		[ resendInvoice ]
	);

	return {
		getInvoicePastDueEventProps,
	};
};

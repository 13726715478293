import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
export type InstallmentPaymentRescheduledByVendorPropsEventFragment = { __typename?: 'Event', id: string, category: Types.EventCategory, invoice?: { __typename?: 'Invoice', id: string, title: string } | null, contact?: { __typename?: 'Contact', id: string, firstName: string, lastName: string, customer: { __typename?: 'User', id: string, email: string } } | null };

export const InstallmentPaymentRescheduledByVendorPropsEventFragmentDoc = gql`
    fragment installmentPaymentRescheduledByVendorPropsEvent on Event {
  id
  category
  invoice {
    id
    title
  }
  contact {
    id
    firstName
    lastName
    customer {
      id
      email
    }
  }
}
    `;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    
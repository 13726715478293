import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
export type InvoiceAcceptedByPlannerEventPropsEventFragment = { __typename?: 'Event', id: string, category: Types.EventCategory, invoice?: { __typename?: 'Invoice', id: string, title: string, contact?: { __typename?: 'Contact', id: string, firstName: string, lastName: string, customer: { __typename?: 'User', id: string, email: string, clientUser?: { __typename?: 'ClientUser', id: string, assignedPlanner?: { __typename?: 'OrgUser', id: string, firstName: string, lastName: string, user?: { __typename?: 'User', id: string } | null, organization?: { __typename?: 'Organization', id: string } | null } | null } | null }, vendor: { __typename?: 'Organization', id: string, name: string } } | null, organization?: { __typename?: 'Organization', id: string } | null } | null, paymentInstallments?: Array<{ __typename?: 'PaymentInstallment', id: string, name?: string | null }> | null };

export const InvoiceAcceptedByPlannerEventPropsEventFragmentDoc = gql`
    fragment invoiceAcceptedByPlannerEventPropsEvent on Event {
  id
  category
  invoice {
    id
    title
    contact {
      id
      firstName
      lastName
      customer {
        id
        email
        clientUser {
          id
          assignedPlanner {
            id
            firstName
            lastName
            user {
              id
            }
            organization {
              id
            }
          }
        }
      }
      vendor {
        id
        name
      }
    }
    organization {
      id
    }
  }
  paymentInstallments {
    id
    name
  }
}
    `;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    